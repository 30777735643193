import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';

// etc
import { AppContext } from '../Context/AppContext';
import { menu } from '../../../configs/menu';
import { Icons } from '../';
import { Logo } from '../';
import { Text } from 'components/lib';

export default function Footer(props) {
  const { w } = props;
  const router = useRouter();
  const classes = {
    contactsTitle: `cursor-default pl-2 border-slate-300 w-full font-bold`,
    contactsValue: `mx-2 font-light zero:text-sm sm:text-base`,
    addressesValue: `mx-2 font-light text-xs`,
  };
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  return (
      <AppContext.Consumer>
        {(app) => {
          return (
              <>
                <footer ref={ref} className={`bg-white `}>
                  {router.asPath !== '/catalog' && inView && (
                      <div className={`h-96 cursor-default`}>
                        <iframe
                            src={'https://api-maps.yandex.ru/frame/v1/-/CCURNHcnTA'}
                            width="100%"
                            height="100%"
                            frameBorder="1"
                            // allowFullScreen='true'
                        ></iframe>
                      </div>
                  )}
                  <div
                      className={`flex py-4 flex-col md:flex-row justify-between items-center h-full bg-green-300`}
                  >
                    <div className={`flex py-4`}>
                      <div className={`flex justify-between`}>
                        <div className={`flex flex-col justify-center px-2 mr-1`}>
                          {menu.map((item, index) => {
                            return (
                                <div className={``} key={`FOOTERMENUITEM${index}`}>
                                  {item.items.map((item_i, index_i) => {
                                    return (
                                        <div
                                            className={`cursor-pointer hover:text-belplit_2`}
                                            key={`FOOTERMENUITEMI${index_i}`}
                                        >
                                          <Link href={`/${item.name}/${item_i.name}`}>
                                            {item_i.title}
                                          </Link>
                                        </div>
                                    );
                                  })}
                                  <div
                                      className={`cursor-pointer hover:text-belplit_2`}
                                  >
                                    {item.show && item.items.length === 0 && (
                                        <Link href={`/${item.name}`}>{item.title}</Link>
                                    )}
                                  </div>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* CONTACTS */}
                      <div className={`flex justify-start flex-wrap w-full`}>
                        <div
                            className={`flex ${
                                w > 420 ? `flex-row` : `flex-col`
                            } justify-between items-stretch w-full`}
                        >
                          <div className={`w-full my-1 flex flex-col`}>
                            <div className={`flex h-6 mx-0.5`}>
                              <Icons.Phone
                                  extraClasses={`w-6 h-6 border-b border-belplit_2 text-belplit_2`}
                              />
                              <p className={classes.contactsTitle}>Телефон:</p>
                            </div>
                            {app.contacts.phones.map((item, index) => (
                                <div
                                    className={`hover:text-belplit`}
                                    key={`FOOTERPHONES${index}`}
                                >
                                  <a
                                      href={`tel:${item}`}
                                      className={classes.contactsValue}
                                  >
                                    {item}
                                  </a>
                                </div>
                            ))}
                            <div className={`flex h-6 mx-0.5`}>
                              <Icons.Mail
                                  extraClasses={`w-6 h-6 border-b border-belplit_2 text-belplit_2`}
                              />
                              <p className={classes.contactsTitle}>EMAIL:</p>
                            </div>
                            <Text className={classes.contactsValue}>
                              {app.contacts.emails[0]}
                            </Text>
                            <div className={`flex h-6 mx-0.5`}>
                              <Icons.Location
                                  extraClasses={`w-6 h-6 pt-0.5 border-b border-belplit_2 text-belplit_2`}
                              />
                              <p className={classes.contactsTitle}>Адрес:</p>
                            </div>
                            <Text className={classes.contactsValue}>
                              {app.contacts.addresses[0].value}
                            </Text>
                            <div className={`${
                                w < 264 ? `flex h-12 mx-0.5` : `flex h-6 mx-0.5`
                            }`}>
                              <Icons.Clock
                                  extraClasses={`w-6 h-6 border-b border-belplit_2 text-belplit_2`}
                              />
                              <p className={classes.contactsTitle}>Время работы:</p>
                            </div>
                            <Text className={classes.contactsValue}>
                              {app.workingHoars.value}
                            </Text>
                            {w < 520 ? (
                                <div>
                                  <div className={`${
                                      w > 264 ? `flex h-6` : `flex h-12`
                                  }`}>
                                    <Icons.Location
                                        extraClasses={`w-6 h-6 pt-0.5 border-b border-belplit_2 text-belplit_2`}
                                    />
                                    <p className={classes.contactsTitle}>Адреса офисов:</p>
                                  </div>
                                  <Text className={classes.addressesValue}>
                                    {[
                                      "<p style=\"font-weight: 700\">Санкт-Петербург:</p><span class=\"text-bold\">БЦ Цитадель, улица Краснопутиловская 69, офис 637.</br>+7 (812) 407-26-30</span>",
                                      "</br><p style=\"font-weight: 700\">Казань:</p><span class=\"text-bold\">улица Восстания, 100, корп. 31.</br>+7 (843) 202-21-08</span>",
                                      "</br><p style=\"font-weight: 700\">Краснодар:</p><span class=\"text-bold\">ул. Российская, 436.</br>+7 (861) 205-07-56</span>",
                                      "</br><p style=\"font-weight: 700\">Астрахань:</p><span class=\"text-bold\">ул. Рождественского 5</br>+7 (851) 299-40-31</span>",
                                      "</br><p style=\"font-weight: 700\">Волгоград:</p><span class=\"text-bold\">ул. Историческая, 187Б</br>+7 (844) 220-08-54</span>",
                                      "</br><p style=\"font-weight: 700\">Ростов на дону:</p><span class=\"text-bold\">пер. Певчий 4А, офис 25</br>+7 (863) 320-00-81</span>",
                                      "</br><p style=\"font-weight: 700\">Крым:</p><span class=\"text-bold\">г. Севастополь, Промышленная 9а</br>+7 (800) 555-25-97</span>",
                                      "</br><p style=\"font-weight: 700\">Чебоксары:</p><span class=\"text-bold\">г. Чебоксары, Ишлейское шоссе, зд. 12А</br>+7 (903) 358-14-23</span>",
                                      "</br><p style=\"font-weight: 700\">Алматы:</p><span class=\"text-bold\">ул. Бокейханова, 147а</br>8 (800) 005-00-29</span>"
                                    ]}
                                  </Text>
                                </div>
                            ) : ''}
                          </div>
                        </div>
                      </div>
                      {w > 520 ? (
                          <>
                            <div className={`flex justify-start flex-wrap w-full`}>
                              <div
                                  className={`flex ${
                                      w > 420 ? `flex-row` : `flex-col`
                                  } justify-between items-stretch w-full`}
                              >
                                <div className={`w-full my-1 flex flex-col`}>
                                  <div className={`${
                                      w > 536 && w > 1160 ? `flex h-6` : `flex h-12`
                                  }`}>
                                    <Icons.Location
                                        extraClasses={`w-6 h-6 pt-0.5 border-b border-belplit_2 text-belplit_2`}
                                    />
                                    <p className={classes.contactsTitle}>Адреса офисов:</p>
                                  </div>
                                  <Text className={classes.addressesValue}>
                                    {[
                                      "<p style=\"font-weight: 700\">Санкт-Петербург:</p><span class=\"text-bold\">БЦ Цитадель, улица Краснопутиловская 69, офис 637.</br>+7 (812) 407-26-30</span>",
                                      "</br><p style=\"font-weight: 700\">Казань:</p><span class=\"text-bold\">улица Восстания, 100, корп. 31.</br>+7 (843) 202-21-08</span>",
                                      "</br><p style=\"font-weight: 700\">Краснодар:</p><span class=\"text-bold\">ул. Российская, 436.</br>+7 (861) 205-07-56</span>",
                                      "</br><p style=\"font-weight: 700\">Астрахань:</p><span class=\"text-bold\">ул. Рождественского 5</br>+7 (851) 299-40-31</span>",
                                      "</br><p style=\"font-weight: 700\">Волгоград:</p><span class=\"text-bold\">ул. Историческая, 187Б</br>+7 (844) 220-08-54</span>"
                                    ]}
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className={`flex justify-start flex-wrap w-full`}>
                              <div
                                  className={`flex ${
                                      w > 420 ? `flex-row` : `flex-col`
                                  } justify-between items-stretch w-full`}
                              >
                                <div className={`${
                                    w > 536 && w > 1160 ? `w-full flex flex-col` : `w-full my-5 flex flex-col`
                                }`}>
                                  <Text className={classes.addressesValue}>
                                    {[
                                      "</br></br><p style=\"font-weight: 700\">Ростов на дону:</p><span class=\"text-bold\">пер. Певчий 4А, офис 25</br>+7 (863) 320-00-81</span>",
                                      "</br><p style=\"font-weight: 700\">Крым:</p><span class=\"text-bold\">г. Севастополь, Промышленная 9а</br>+7 (800) 555-25-97</span>",
                                      "</br><p style=\"font-weight: 700\">Чебоксары:</p><span class=\"text-bold\">г. Чебоксары, Ишлейское шоссе, зд. 12А</br>+7 (903) 358-14-23</span>",
                                      "</br><p style=\"font-weight: 700\">Алматы:</p><span class=\"text-bold\">ул. Бокейханова, 147а</br>8 (800) 005-00-29</span>"
                                    ]}
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </>
                      ) : ''}
                    </div>

                    <div
                        className={`w-full flex flex-col justify-end items-end text-zinc-800 cursor-default`}
                    >
                      <div className={`w-full p-12`}>
                        <Link href="/main" passHref>
                          <Logo extraClasses={`w-full cursor-pointer`}/>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                      className={`py-4 flex items-center bg-zinc-800 text-zinc-100 justify-center gap-2`}
                  >
                    <p className={`text-center`}>
                      2022. Сайт создан с помощью{' '}
                      <a className={`font-bold`} href="https://roboweb.team/">
                        RoboWeb
                      </a>
                    </p>
                    <a href="https://roboweb.team/">
                      <Icons.Roboweb
                          fill={`#ffffff`}
                          extraClasses={`w-10 h-10 hover:scale-105 transition-all`}
                      />
                    </a>
                  </div>
                </footer>
              </>
          );
        }}
      </AppContext.Consumer>
  );
}
