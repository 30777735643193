
import { default as CardSM } from './Card.sm';
import { default as List } from './List';
import { default as Techdesc } from './Techdesc';

const ProductComponent = {
  CardSM,
  List,
  Techdesc,
};

export default ProductComponent;
