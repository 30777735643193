import React from 'react';
import { SVGImage } from './Svg';

export const Logo = (props) => {
  return (
    <SVGImage
      viewBox='0 0 512 66.15'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <defs>
        <style>{'.prefix__fil0{fill:#363636}'}</style>
      </defs>
      <g id='prefix__\u0421\u043B\u043E\u0439_x0020_1'>
        <g id='prefix___3250402944256'>
          <path
            className='prefix__fil0'
            d='M12.12 42.83h28.29v9.42H12.12v-9.42zm0-25.6h25.59v9.43H12.12v-9.43zM0 67.07h39.06c20.2 0 16.16-32.32 10.77-35.02 0-12.12 4.04-30.98-10.77-30.98H0v66zM330 17.23h26.93v13.48H330V17.23zm26.93 29.64c6.74 0 13.47-1.35 13.47-12.12.02-.49 0-18.4 0-18.86 0-17.51-12.25-14.81-32.32-14.82h-21.55v66H330v-20.2h10.77l13.47 20.2h16.16l-13.47-20.2zM431.02 29.36v37.71h13.47v-66h-13.47l-16.17 25.59-17.51-25.59h-13.47v66h13.47V29.36l17.51 21.55zM471.42 17.23h26.94v35.02h-26.94V17.23zm32.33 49.84c4.04 0 8.08-2.69 8.08-16.16-.04-9.95.4-24.13 0-32.33-.61-12.75-3.72-17.3-17.44-17.23-32.5.18-36.9-4.64-36.79 23.97.14 35.6-4.02 41.92 17.86 41.75 10.07-.08 18.86 0 28.29 0zM268.04 17.23h36.36V1.07h-49.83v66h49.83V52.25h-36.36v-9.42h29.63V26.66h-29.63zM115.83 17.23V1.07H67.34v66h48.49V52.25H80.81v-9.42h28.29V26.66H80.81v-9.43zM154.89 17.23h55.23v49.84h13.47V17.23h20.2V1.07h-88.9z'
          />
          <path className='prefix__fil0' d='M129.3 67.07H198V52.25h-55.23V1.07l-13.57.07z' />
          <path
            d='M154.89 44.85c15.03 0 30.76 2.68 36.86-4.26 4.11-4.68 6.14-9.82 5.92-16.29-21.83 0-40.94-4.62-42.78 20.55z'
            fill='#00a936'
          />
        </g>
      </g>
    </SVGImage>
  );
};

export default Logo;
